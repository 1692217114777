/*
 * @Author: r_yanxu r_yanxu@ctx.com.sg
 * @LastEditors: r_yanxu r_yanxu@ctx.com.sg
 * @Date: 2025-01-20 16:38:22
 * @Description: 
 */
import postData from "@/common/cHttp";

//注册
export const userRegister = (params) => {
    return postData('/api/t/user/register', {
        json: params,
    })
}

//登录
export const userLogin = (params) => {
    return postData('/api/t/user/login', {
        json: params,
    })
}

//更新个人资料
export const userUpdate = (params) => {
    return postData('/api/t/user/update', {
        json: params,
    })
}

//登录或注册
export const userLoginOrReg = (params) => {
    return postData('/api/t/user/loginOrReg', {
        json: params,
    })
}

//第三方登录(谷歌登录)
export const thirdPartyLogin = (params) => {
    return postData('/api/t/user/thirdPartyLogin', {
        json: params,
    })
}
<template>
  <div class="articleForm box">
    <!-- {{ parentData }} -->

    <div class="title is-5 is-success">
      {{ (parentData.id ? "编辑 " : "创建 ") + "景点" }}
    </div>

    <section class="box">
      <b-field label="景点ID :" horizontal v-if="parentData.id">
        {{ parentData.id }}
      </b-field>
      <b-field label="英文名称:" horizontal>
        <b-input size="is-small" v-model="parentData.enName" :value="parentData.enName" required></b-input>
      </b-field>
      <b-field label="中文名称:" horizontal>
        <b-input
          size="is-small"
          :value="parentData.cnName"
          required
          v-model="parentData.cnName"
        ></b-input>
      </b-field>

      <b-field label="推荐上热门:" horizontal>
        <b-radio v-model="isHot" size="is-small" native-value="1" required 
          >是</b-radio
        >
        <b-radio v-model="isHot" size="is-small" native-value="0" required
          >否</b-radio
        >
      </b-field>
      <b-field label="景点类型:" horizontal>
        <section>
          <b-tooltip
            v-for="(key, index) in sceneTypeList"
            :key="index"
            :label="key.desc"
            type="is-info is-light"
            multilined
          >
            <b-checkbox
              size="is-small"
              v-model="sceneTypeIdList"
              :native-value="key.id"
            >
              {{ key.name }}
            </b-checkbox>
          </b-tooltip>
        </section>
      </b-field>

      <!-- <b-field label="图片链接:" :type="urlStatus" horizontal required>
        <b-input
          size="is-small"
          type="url"
          :value="parentData.imgUrl"
          maxlength="5300"
          v-model="parentData.imgUrl"
        >
        </b-input>
      </b-field> -->
      <b-field label="图片" horizontal>
        <div>
          <div v-for="(item, index) in imgUrlList" :key="index" style="display: flex; margin-bottom: 5px;">
            <b-input
              v-model="item.img"
              size="is-small"
              style="width: 90%; margin-right: 10px;"
            />
            <b-button
              v-if="index === imgUrlList.length - 1"
              size="is-small"
              icon-left="plus"
              type="is-success"
              style="margin-right: 10px;"
              @click="handleAddImgList"
            >
              新增
            </b-button>

            <b-button
              v-if="index !== imgUrlList.length - 1"
              size="is-small"
              icon-left="delete"
              type="is-danger"
              @click="handleDeleteImgList(index)"
            >
              删除
            </b-button>
          </div>
        </div>
        
      </b-field>
      <b-field label="视频链接:" :type="urlStatus" horizontal required>
        <b-input
          size="is-small"
          type="url"
          :value="parentData.videoUrl"
          maxlength="500"
          v-model="parentData.videoUrl"
        >
        </b-input>
      </b-field>

      <b-field label="国家:" horizontal>
      
        <section>
         
          <b-radio
            size="is-small"
            v-model="countryId"
            v-for="(key, index) in countryList"
            :key="index"
            :native-value="key.id"
            @click.native="$event=>changeCountry($event,key.id)"
          >
            {{ key.name }}
          </b-radio>
          <!-- </b-tooltip> -->
        </section>
      </b-field>
      <b-field label="城市:" horizontal>
        <b-select
          size="is-small"
          placeholder="选择城市"
          required
          v-model="cityId"
          filterable
          clearable
        >
        <option value="">请选择</option>
          <option
            v-for="(option, index) in cityList"
            :value="option.id"
            :key="index"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="景点地址" horizontal>
        <b-input
          v-model="parentData.address"
          size="is-small"
          maxlength="100"
          type="text"
          :value="parentData.address"
          required
        ></b-input>
      </b-field>
      <b-field label="旅游季节" horizontal>
        
        <b-checkbox
          v-model="seasonToVisitList"
          native-value="spring"
         
          size="is-small"
        >
          <span>Spring</span>
        </b-checkbox>

        <b-checkbox
          v-model="seasonToVisitList"
          native-value="summer"
          
          size="is-small"
        >
          <span>Summer</span>
        </b-checkbox>
        <b-checkbox
          v-model="seasonToVisitList"
          native-value="autumn"
         
          size="is-small"
        >
          <span>Autumn</span>
        </b-checkbox>
        <b-checkbox
          v-model="seasonToVisitList"
          native-value="winter"
          
          size="is-small"
        >
          <span>Winter</span>
        </b-checkbox>
      </b-field>
      <b-field label="旅游月份" horizontal>
        <!-- <b-input
          size="is-small"
          v-model="parentData.monthToVisit"
          :value="parentData.monthToVisit"
          required
          min="1"
          max="12"
        >
        </b-input> -->
        <section>
          <b-checkbox
            size="is-small"
            v-model="monthToVisitList"
            :native-value="c"
            v-for="(c,index) in months"
            :key="index"
          >
            {{ c }}
          </b-checkbox>
        </section>
      </b-field>
      <b-field label="景点描述:" horizontal>
        <b-input
          v-model="parentData.desc"
          size="is-small"
          :value="parentData.desc"
          required
        ></b-input>
      </b-field>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <b-button
            type="is-primary is-success"
            size="is-small"
            @click="confirmBtn"
            >确认</b-button
          >
        </div>
      </div>
    </section>

    <!-- {{ parentData }} -->
  </div>
</template>
  <script>
import postData from "../../../common/http";
export default {
  updated() {
    // console.log("entered")
    // this.getAllType()
  },
  props: {
    parentData: {
      type: Object,
    },
  },
  methods: {
    handleAddImgList() {
        this.imgUrlList.push({
          img: ""
        })
      },
      handleDeleteImgList(index) {
        this.imgUrlList.splice(index, 1)
      },
    changeCountry($event, id) {
      this.countryId = id;
      this.cityId = "";
      this.cityList = [];
      this.getCityList();
    },
    async getCountryList() {
      let list = await postData("/city/list", {
        json: {
          parentId: 0,
        },
      });
      if (list) {
        this.countryList = list;
        this.$forceUpdate();
      }
    },
    async getList(type) {
      let list = await postData(this.commonTypeListPath, {
        json: {
          typeIdentity: type,
        },
      });

      return list;
    },
    async confirmBtn(item) {
      if (!this.parentData.id) delete this.parentData.id;
      
      delete this.parentData.city;
      delete this.parentData.sceneTypeList;
      delete this.parentData.seasonToVisit;
      delete this.parentData.monthToVisit;
      delete this.parentData.imgUrlList;
      let imgUrlArr = [];
      if (this.imgUrlList.length) {
        imgUrlArr = this.imgUrlList.filter(item => item.img).map(curr => {
          return curr.img;
        })
      }
      let json={
        ...this.parentData,
        sceneTypeIdList:this.sceneTypeIdList,
        seasonToVisitList:this.seasonToVisitList,
        monthToVisitList:this.monthToVisitList,
        cityId:this.cityId,
        isHot:this.isHot,
        countryId: this.countryId,
        imgUrlList: imgUrlArr
      }
      console.log(json, "312312312312312313123123123");
      let data = await postData(this.savePath, {
        json: json
      });

      if (data == null) {
        this.$buefy.toast.open("成功");
      }
    },
    getAllType() {
      this.getList("sceneType").then((data) => {
        this.sceneTypeList = data;
      });
    },
    formateParameter(){
      this.sceneTypeIdList=this.parentData.sceneTypeList.length>0?this.parentData.sceneTypeList.map(item=>item.id):[];
      this.isHot=this.parentData.isHot||0;;
      this.countryId=this.parentData.countryId;
      if (this.countryId) {
        this.getCityList();
        this.cityId = this.parentData.cityId;
      }
      this.imgUrlList = [{
        img: ""
      }]
      if (this.parentData.imgUrlList && this.parentData.imgUrlList.length) {
        this.imgUrlList = this.parentData.imgUrlList.map(item => {
          return {
            img: item
          }
        });
      }
      this.monthToVisitList=this.parentData.monthToVisitList||[];
      this.seasonToVisitList=this.parentData.seasonToVisitList||[];
      this.$forceUpdate()
    },
    async getCityList() {
      let list = await postData("/city/list", {
        json: {parentId:this.countryId},
      });
      this.cityList = list.sort((a, b) => a.name.localeCompare(b.name));
    },

    canParseJSON(str) {
      try {
          JSON.parse(str);
          return true; // 如果没有抛出异常，表示可以解析
      } catch (e) {
          if (e instanceof SyntaxError) {
              return false; // 抛出SyntaxError，表示格式错误，不能解析
          }
          throw e; // 如果不是SyntaxError，重新抛出异常
      }
    },
  },
  data() {
    return {
      sceneTypeList: [],
      isHot:0,
      sceneTypeIdList: [],
      seasonToVisitList: [],
      monthToVisitList: [],
      imgUrlList: [],
      cityId: "",
      typeIdentity: "sceneType",
      commonTypeListPath: "/commonType/list",
      urlStatus: "is",
      cityList: [],
      countryList: [],
      countryId: "",
      savePath: "/scene/update",
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    };
  },
  created() {
    this.formateParameter()
    this.getCountryList();

    this.getAllType();
  },
  beforeCreated() {},
};
</script>
  
  <style lang="scss" scoped>
.articleForm {
  padding-bottom: 100px;
  min-height: 95vh;
}
</style>
<template>
  <div class="article">
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            size="is-small"
            icon-left="plus"
            type="is-success"
            @click="addNew"
            >添加</b-button
          >
        </div>
      </div>
    </div>
    <div class="hero is-warning is-small">
      <div class="hero-body">
        <b-field label="根据国家选择景点:">
          <section>
            <b-radio
              size="is-small"
              v-model="countryId"
              v-for="(key, index) in cityList"
              :key="index"
             
              :native-value="key.id"
              @click.native="$event=>changeCity($event,key.id)"
            >
              {{ key.name }}
            </b-radio>
            <!-- </b-tooltip> -->
          </section>
        </b-field>
      </div>
    </div>

    <b-table

      size="is-small"
      :stickyHeaders="stickyHeaders"
      :data="list"
      hoverable
      :debounce-search="1000"
      :loading="isLoading"
    >
      <b-table-column
        v-for="(column) in columnsTemplate"
        :key="column.field"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <span>{{ props.row[column.field] }}</span>
      </b-table-column>
      <b-table-column  v-slot="props" label="推荐上热门">
        {{ props.row.isHot==0?"否":"是" }}
      </b-table-column>
      <b-table-column
        v-for="(column) in columnsInfo"
        :key="column.field"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <section v-if="column.field=='sceneTypeList'">
          <b-tooltip
            :label="key.desc"
            v-for="(key, index) in props.row[column.field]"
            :key="index"
            type="is-success is-dark"
            multilined
          >
            <b-tag type="is-link is-light" size="is-small" >
              {{ key.name }}
            </b-tag>
             
          </b-tooltip>
        </section>
        <section v-if="column.field=='country'">
          <b-tooltip
            :label="props.row[column.field] ? props.row[column.field].name : ''"
           
            type="is-info is-light"
            multilined
          >
            <b-tag type="is-link is-success" size="is-small" >
              {{ props.row[column.field] ? props.row[column.field].name : '' }}
            </b-tag>
             
          </b-tooltip>
        </section>
        <section v-if="column.field=='city'">
          <b-tooltip
            :label="props.row[column.field] ? props.row[column.field].name : ''"
           
            type="is-info is-light"
            multilined
          >
            <b-tag type="is-link is-success" size="is-small" >
              {{ props.row[column.field] ? props.row[column.field].name : "" }}
            </b-tag>
             
          </b-tooltip>
        </section>
      </b-table-column>

      <b-table-column
        v-for="(column) in columnsTime"
        :key="column.field"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <section>
    
       
            <b-tag type="is-link is-light" size="is-small"  v-for="(key, index) in props.row[column.field]" :key="index"> {{
              key.name || key
            }}</b-tag>
        
        </section>
      </b-table-column>

      <b-table-column label="操作" v-slot="props">
        <div class="controlPanel">
          <b-button
            size="is-small"
            icon-left="lead-pencil"
            @click="edit(props.row)"
            type="is-info"
            >编辑
          </b-button>

          <!-- <b-button
              size="is-small"
              icon-left="publish"
              @click="publish(props.row)"
              type=""
            >
            </b-button> -->

          <b-button
            size="is-small"
            icon-left="delete"
            @click="deleteBtn(props.row)"
            type="is-danger"
            >删除
          </b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="openDrawer"
      :on-cancel="closeSideBar"
    >
      <sceneForm :parentData="dataToForm" v-if="openDrawer"></sceneForm>
    </b-sidebar>
  </div>
</template>
  <script>
import sceneForm from "./sceneForm.vue";
import postData from "../../../common/http";
export default {

  data() {
    return {
      isLoading: false,
      openSideBar: false,
      fullwidth: true,
      stickyHeaders: true,
      showAllColumns: false,
      fullheight: true,
      typeIdentity: "sceneType",
      deleteItemPath: "/scene/delete",
      right: true,
      isOverlay: true,
      openDrawer: false,
      listPath: "/scene/list",
      list: [],
      columnsTemplate: [
        { title: "ID", field: "id", visible: true, searchable: true },
        {
          title: "英文名称",
          field: "enName",
          visible: true,
          searchable: true,
        },
        {
          title: "中文名称",
          field: "cnName",
          visible: true,
          searchable: true,
        },
        // {
        //   title: "图片链接",
        //   field: "imgUrl",
        //   visible: true,
        //   searchable: true,
        // },
       
        { title: "视频链接", field: "videoUrl", visible: true },
       
        { title: "地址", field: "address", visible: true },
        { title: "描述", field: "desc", visible: true },
      ],
      columnsInfo: [
        { title: "景点类型", field: "sceneTypeList", visible: true },
        { title: "国家", field: "country", visible: true },
        { title: "城市", field: "city", visible: true }
      ],
      columnsTime: [
        { title: "最佳旅游季节", field: "seasonToVisitList", visible: true },

        { title: "最佳旅游月份", field: "monthToVisitList", visible: true },
      ],
      cityList: [],
    };
  },
  methods: {
    changeCity($event,id){
      // console.log(id,33);
      // console.log(this.countryId,44);
      this.countryId=id;
      this.getList()
    },
    addNew() {
      this.dataToForm = {
        enName: "",
        cnName: "",
        desc: "",
        sceneTypeList: [],
        imgUrl: "",
        videoUrl: "",
        address: "",
        city:{id:""},
        seasonToVisit: [],
        monthToVisit: [],
        sceneTypeList:[],
        countryId:this.countryId,
        cityId: ""
      };
      console.log(this.dataToForm);
      this.openDrawer = true;
      this.openSideBar = true;
    },
    toggleColumns() {
      this.showAllColumns != this.showAllColumns;
    },
    edit(item) {
      this.dataToForm = item.id ? item : { city: {} };
      this.dataToForm.countryId=this.countryId;
      this.dataToForm.cityId = item.city? item.city.id : "";
      // this.dataToForm.typeIdentity=this.typeIdentity;
      this.openDrawer = true;
      this.openSideBar = true;
    },
    async deleteBtn(item) {
      // console.log(item, 33);
      let data = await postData(this.deleteItemPath, {
        json: { id: item.id },
      });
     
      if (data == null) {
        this.$buefy.toast.open("success");
      }
    },
    async getList() {
      this.isLoading = true;
      let data = await postData(this.listPath, {
        json: {
          countryId: this.countryId,
        },
      });
      if (data) {
        this.list = data;
        this.isLoading = false;
      }
    },
    closeSideBar() {
      this.openSideBar = false;
      this.getList();
    },
    async getCityList() {
      let list = await postData("/city/list", {
        json: {
          parentId: this.countryId || 0,
        },
      });
      if (list) {
        this.cityList = list;
        this.countryId = this.cityList[0].id;
        console.log(this.countryId, 333);
        this.getList();
      }
    },
  },
  created() {
    this.getCityList();
  },
 
  components: { sceneForm },

};
</script>
  
  <style lang="scss" scoped>
.article {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.sidebar-content {
  min-width: 500px !important;
}
.controlPanel {
  display: flex;
  justify-content: space-around;
}
</style>
  
  
  
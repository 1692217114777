<template>
  <div class="login hero is-warning">
    <div class="box boxContainer">
      <div class="googlelogin">
        <!-- <b-button disabled expanded type="is-link">Continue with Google</b-button> -->
        <div id="g_id_onload" data-client_id="648077353285-9sghvmp5klia2di3p20lh9qg58a767ri.apps.googleusercontent.com"
          data-context="signin" data-ux_mode="popup" data-callback="getGoogleInfo"
          data-login_uri="http://localhost:8080" data-auto_prompt="false">
        </div>

        <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline"
          data-text="signin_with" data-size="large" data-locale="en-US" data-width=400 data-logo_alignment="left">
        </div>
      </div>

      <div class="title is-h3 email">or</div>
      <b-field label="use Email address to login" type="is-danger">
        <b-input type="text" value="" maxlength="30" v-model="user.username">
        </b-input>
      </b-field>
      <b-field label="Password">
        <b-input type="password" value="iwantmytreasure" password-reveal v-model="user.password">
        </b-input>
      </b-field>
      <div class="content">
        <b-button type="is-success" size="is-small" expanded @click="loginBtn">登录</b-button>
      </div>
      <div class="level is-mobile">
        <div class="level-item"><a href="">Beta version with limited functions</a></div>
        <div class="level-item"><span>|</span></div>

        <div class="level-item" @click="goSign">去注册</div>
      </div>
      <div class="container">
        Please send email to power998@163.com if interest arises;
      </div>
    </div>
  </div>
</template>
<script>
import postData from "../../common/cHttp";
export default {
  data() {
    return {
      user: {},
      loginPath: "/api/t/user/login",
    };
  },
  methods: {
    getGoogleInfo(res) {
      console.log(res)
      // console.log("Sign in with Google button clicked...")
    },
    async loginBtn() {
      let data = await postData(this.loginPath, {
        json: this.user,
      });
      if (data.apiToken && data.username) {
        localStorage.setItem("crm-userName", data.username);
        localStorage.setItem("crm-token", data.apiToken);
        localStorage.setItem("crm-id", data.id);
        localStorage.setItem("crm-email", data.email);
        if (sessionStorage.getItem('redirect')) {
          this.$router.push(sessionStorage.getItem('redirect'))
        } else {
          window.location.href = "/";
        }
        this.$forceUpdate();
      }
    },
    goSign() {
      this.$router.push('/signup')
    }
  },
  mounted() {
    window.getGoogleInfo = this.getGoogleInfo; // 关键：将方法注册为全局函数

    // 加载谷歌客户端库
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
  },
};
</script>
<style lang="scss" scoped>
.login {
  // margin-top:10rem;
  padding-top: 5rem;
  display: flex;
  justify-content: center;

  .googlelogin {
    margin-top: 1rem;
  }

  .boxContainer {
    margin: 0 auto;
    width: 30%;
    min-width: 280px;
    height: 35rem;

    .email {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<template>
  <section class="sitemap-container">
    <nav class="nav">
      <ul class="nav-list">
        <li class="nav-item" v-for="(item, index) in sitemap" :key="item.name">
          <router-link
            :to="item.path"
            :class="{ active: item.path === $route.path }"
            >{{ item.name }}
        </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <div class="content-list" v-if="!isHideContentList">
        <div class="content-item" v-for="item in contentList" :key="item.name">
          <router-link
            :to="{ name: item.path2, params: { name: item.name } }"
            >{{ item.name }}</router-link
          >
        </div>
      </div>
      <div class="content-view" v-if="isHideContentList">
        <div class="menu-list">
          <div class="menu-item" v-for="item in contentList" :key="item.name">
            <router-link
              :class="{ active: item.name === $route.params.name }"
              :to="{ name: item.path2, params: { name: item.name } }"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "sitemap",
  data() {
    return {
      sitemap: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Sitemap",
          path: "/sitemap",
        },
      ],
      contentList: [
        {
          name: "Hots",
          path: "/hots",
          path2: "mapContent",
        },
        {
          name: "Destinations",
          path: "/destinations",
          path2: "mapContent"
        },
        {
          name: "Blogs",
          path: "/blogs",
          path2: "mapContent",
        },
        {
          name: "News",
          path: "/travel-destination-news",
          path2: "mapContent",
        },
        {
          name: "FAQ",
          path: "/faq",
          path2: "mapContent",
        },
        {
          name: "Groups",
          path: "/groups",
          path2: "mapContent",
        },
        {
          name: "Tools",
          path: "/tools",
          path2: "mapContent",
        },
      ],
      isHideContentList: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to.path)
      this.isHideContentList = to.path !== "/sitemap";
      if (this.isHideContentList) {
        const map = {
          name: to.params.name,
          path: to.path,
        };
        this.sitemap.splice(2, 0, map);
        this.sitemap.splice(3, this.sitemap.length - 3);
      } else {
        this.sitemap.pop();
      }
    },
  },
  created() {
    // console.log(this.$route.path)
    const map = {
      name: this.$route.params.name,
      path: this.$route.path,
    };
    console.log(map)
    this.sitemap.splice(2, 0, map);
    this.sitemap.splice(3, this.sitemap.length - 3);
    if (map.name) {
      this.isHideContentList = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.sitemap-container {
  margin: 60px 30px 30px 30px;
  background-color: initial;
  .nav {
    width: 100%;
    margin-bottom: 30px;
    .nav-list {
      display: flex;
      align-items: center;
      .nav-item {
        a {
          font-size: 16px;
          font-weight: 600;
          color: #121212;
          cursor: pointer;
          &.active {
            color: #0057d9;
          }
          .arrow {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .content-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .content-item {
      width: 32%;
      height: 400px;
      background-color: #f8fafc;
      border-radius: 10px;
      position: relative;
      a {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
  .content-view {
    display: flex;
    .menu-list {
      display: flex;
      flex-direction: column;
      gap: 8px 0;
      width: 251px;
      .menu-item {
        a {
          display: block;
          width: 50%;
          height: 36px;
          border-radius: 4px;
          padding: 8px 12px;
          font-size: 14px;
          font-weight: 600;
          &.active {
            background-color: #fafafc;
            color: #0057d9;
          }
          &:hover {
            background-color: #fafafc;
          }
        }
      }
    }
  }
}
</style>

import Vue from "vue"
import VueRouter from 'vue-router'

import { replaceWithDashSpaces } from '@/utils/utils'

import main from '../views/main'
import config from '../views/config/index.vue'
import ArticleList from '../views/blog/articleList'
import Blog from '../views/blog/index.vue'
import Scene from '../views/blog/scene.vue'
import List from '../views/blog/list.vue'
import tabs from '../common/config/tabs'
// import Countries from '../views/countries/index'
import Login from '../views/user/login.vue'
import Editor from '../views/user/editor.vue'
import Hots from '../views/hots/index.vue'
import News from '../views/news/index.vue'
import Scenes from '../views/scenes/index.vue'
import Headline from '../views/headline/index.vue'

import AboutUs from "../views/blog/aboutus"
import ContactUs from "../views/blog/contactus"
import Sitemap from '../views/sitemap/index'
import MapContent from '../views/sitemap/mapContent'

import NewArtcleList from '../views/blog/newArtcleList.vue'  // 新版首页
import CricleIndex from '../views/circle/index.vue' // 圈子
import GroupDetail from '../views/circle/detail.vue' // 圈子详情
import Discover from '@/views/discover'
import ArticleDetail from '../views/circle/articleDetail'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "index",
        component: Blog,
        children: [
            {
                path: "",
                component: NewArtcleList,
                name: "NewArtcleList",
                meta: {
                    tab: "Hot"
                }
            },
            {
                path: "/ArticleList",
                component: ArticleList,
                name: "ArticleList"
            },
            {
                path: "/groups",
                component: CricleIndex,
                name: "CricleIndex",
                meta: {
                    tab: "Groups",
                }
            },
            {
                path: "/groupDetail/:groupName",
                component: GroupDetail,
                name: "groupDetail",
                meta: {
                    tab: "Groups",
                }
            },
            {
                path: "/us/articleDetail",
                component: ArticleDetail,
                name: "ArticleDetail"
            },
        ]
    },
    {
        path: "/about-us",
        name: "about-us",
        component: AboutUs
    },
    {
        path: "/contact",
        name: "contactus",
        component: ContactUs
    },
    {
        name: "sitemap",
        path: "/sitemap",
        component: Sitemap,
        children: [
            {
                name: "mapContent",
                path: "/sitemap/:name",
                component: MapContent
            }
        ]
    },

    {
        path: "/hots",
        name: "hots",
        component: Hots
    },
    {
        path: "/news",
        name: "news",
        component: News
    },
    //等级规则
    {
        path: "/ratingRules",
        name: "ratingRules",
        component: () => import('@/views/ratingRules/index.vue')
    },
    //discover
    {
        path: "/discover",
        name: 'discover',
        component: Blog,
        meta: {
            title: "Discover"
        },
        children: [
            {
                path: "/discover/:countryName?/:cityName?/:triptypeName?",
                component: () => import('@/views/discover/index.vue'),
                // name: "NewArtcleList",
                meta: {
                    tab: "Discover",

                    title: "Discover best travel experience"

                }
            }
        ]
    },
    //destinations
    {
        path: "/destinations",
        name: 'destinations',
        component: Blog,
        meta: {
            title: "Discover"
        },
        children: [
            {
                path: "/destinations/:countryName?/:cityName?/:triptypeName?",
                component: () => import('@/views/discover/index.vue'),
                // name: "NewArtcleList",
                meta: {
                    tab: "Discover",

                    title: "Discover best travel experience"

                }
            }
        ]
    },
    {
        path: "/travel",
        name: 'travel',
        component: Blog,
        children: [
            {
                path: "popular-country/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: Discover,
                meta: {
                    tab: "Discover",
                    title: "Discover hot travel experience "

                }
            },
            {
                path: "popular-travel-types/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: Discover,
                meta: {
                    tab: "Discover",
                    title: "'Discover hot travel experience "

                }
            },
            {
                path: "popular-types/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: Discover,
                meta: {
                    tab: "Discover",
                    title: "'Discover hot travel experience "

                }
            },
            {
                path: "/travel/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: () => import('@/views/discover/index.vue'),
                meta: {
                    tab: "Discover",
                    title: "'Discover hot travel experience "

                }
            }
        ]
    },

    {
        path: "/travel-news",
        name: "Details",
        component: Blog,
        children: [
            {
                path: "/travel-news/:countryName?/:headline?",
                component: () => import('@/views/details/index.vue'),
                meta: {
                    tab: "Discover",
                    title: "Discover hot travel news"
                },
            },
            {
                path: "/hot-topic/:countryName?/:headline?",
                component: () => import('@/views/details/index.vue'),
                meta: {
                    tab: "Hot",
                    title: "Discover "
                },
            },
            {
                path: "/hot-news/:countryName?/:headline?",
                component: () => import('@/views/details/index.vue'),
                meta: {
                    tab: "Hot",
                    title: "Discover hot news"
                },
            }
        ]
    },

    {
        path: "/travel-country/:countryName?",
        name: 'travel country',
        component: () => import('@/views/country/index.vue'),
        meta: {
            tab: "Hot",
        },
    },
    {
        path: "/country/:countryName?",
        name: 'Country',
        component: () => import('@/views/country/index.vue'),
        meta: {
            tab: "Hot",
        },
    },

    {
        path: "/travel-attractions/:countryName?/:attractionName?",
        name: 'TravelAttractions',
        component: () => import('@/views/scenicSpot/index.vue'),
        meta: {
            tab: "Hot",
        },
    },

    {
        path: "/travel-cities/:countryName?",
        name: 'TravelCities',
        component: () => import('@/views/cities/index.vue'),
        meta: {
            tab: "Hot",
        },
    },

    {
        path: "/travel-city/:countryName/:cityName",
        name: 'TravelCity',
        component: () => import('@/views/city/index.vue'),
        meta: {
            tab: "Hot",
        },
    },
    {
        path: "/city/:countryName/:cityName",
        name: 'city',
        component: () => import('@/views/city/index.vue'),
        meta: {
            tab: "Hot",
        },
    },

    {
        path: "/hot-travel-topic/:articletypeName?/list",
        name: 'HotTopPicList',
        component: () => import('@/views/blog/hotTopPicList.vue'),
        meta: {
            tab: "Hot",
            title: "Discover"
        }
    },

    {
        path: "/hot-travel-topic/:countryName?/:articletypeName?/detail",
        name: 'HotTopPicDetail',
        component: () => import('@/views/details/index.vue'),
        meta: {
            tab: "Hot",
            title: "Discover"
        }
    },

    {
        path: '/News-This-Week',
        name: 'NewsWeek',
        component: () => import('@/views/newsWeek/index.vue'),
        meta: {
            tab: "News",
            title: "News This Week"
        }
    },

    {
        path: '/travel-destination-news',
        name: 'TravelDestinationNews',
        component: () => import('@/views/newsWeek/index.vue'),
        meta: {
            tab: "News",
            title: "Travel News"
        }
    },

    {
        path: "/travel-destination-news/:countryName?/news",
        name: "CountryTravelNews",
        component: () => import("@/views/newsWeek/countryNewsList.vue"),
        meta: {
            tab: "News",
            title: "News hot travel experience"
        }
    },

    {
      path: "/travel-destination-news/:countryName?/cites",
      name: "CountryCites",
      component: () => import("@/views/newsWeek/cites.vue"),
      meta: {
          tab: "News",
          title: "News hot travel experience"
      }
    },

    {
      path: "/travel-destination-news/:countryName?/:cityName?/news",
      name: "CityNews",
      component: () => import("@/views/newsWeek/citesNews.vue"),
      meta: {
          tab: "News",
          title: "Travel Country News"
      }
  },

    {
        path: "/travel-destination-news/:countryName?/:headline?",
        name: "CountryTravelNewsDetail",
        component: () => import("@/views/newsWeek/newsDetail.vue"),
        meta: {
            tab: "News",
            title: "News hot travel experience"
        }
    },

    {
        path: "/hot-travel-faq",
        name: "HotFaq",
        component: () => import("@/views/faq/index.vue"),
        meta: {
            tab: "FAQ",
            title: "Hot Travel faq"
        }
    },

    {
        path: "/travel-faq/:countryName?/question",
        name: "HotCountryFaq",
        component: () => import("@/views/faq/countryFaq.vue"),
        meta: {
            tab: "FAQ",
            title: "Travel Country faq"
        }
    },

    {
      path: "/travel-faq/:countryName?/cites",
      name: "HotCountryFaq",
      component: () => import("@/views/faq/cites.vue"),
      meta: {
          tab: "FAQ",
          title: "Travel Country cites"
      }
    },

    {
        path: "/travel-faq/:countryName?/:cityName?/question",
        name: "CityFaq",
        component: () => import("@/views/faq/citesFaq.vue"),
        meta: {
            tab: "FAQ",
            title: "Travel Country faq"
        }
    },

    {
        path: "/hot-travel-faq-detail/:countryName?/:headline?",
        name: "HotFaqDetail",
        component: () => import("@/views/faq/detail.vue"),
        meta: {
            tab: "FAQ",
            title: "Hot Travel faq Detail"
        }
    },
    {
        name: "hot-countries",
        path: "/hot-countries",
        component: List,

    },
    {
        name: "hot-cities",
        path: "/hot-cities",
        component: List,
    },
    {
        name: "scenes",
        path: "/scenes",
        component: Scenes,
    },
    {
        path: "/scene",
        name: "scene",
        component: Scene
    },

    {
        path: "/config",
        name: "config",
        component: config,
        children: tabs
    },
    {
        name: "login",
        path: "/login",
        component: Login
    },
    {
        name: "editor",
        path: "/editor",
        component: Editor
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import('@/views/user/signup.vue')
    },
    // {
    //     name: "404",
    //     path: "/404",
    //     component: () => import('@/views/404/index.vue')
    // },
    {
        path: "/mine",
        name: "mine",
        component: () => import('@/views/mine/index.vue'),
        children: [
            {
                path: "home",
                mame: 'home',
                component: () => import('@/views/mine/pages/home/index.vue'),
            },
            {
                path: "settings",
                name: 'settings',
                component: () => import('@/views/mine/pages/settings/index.vue'),
            },
        ]
    },
    {
        path: "/signin",
        name: "signin",
        component: () => import('@/views/signin/index.vue'),
        children: [
            {
                path: "signin",
                mame: 'signin',
                component: () => import('@/views/signin/pages/signin/index.vue'),
            },
            {
                path: "growth",
                name: 'growth',
                component: () => import('@/views/signin/pages/growth/index.vue'),
            }
        ]
    },

    // {
    //     path: '*',
    //     redirect: '/404'
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    //记录从哪个页面跳转登录页面
    if (to.path === '/login') {
        if (from.path && from.path !== '/signup') {
            sessionStorage.setItem('redirect', from.path)
        }
    }

    //  修改title 
    if (to.params) {
        const { countryName, cityName, tripTypeName, headline, subtitle } = to.params;
        // if(subtitle) document.title=subtitle;




        if (countryName && headline) {
            document.title = countryName + " travel - " + document.title + " : " + headline

        }

    }
    next()
})

export default router